import { newEngine } from './snippets/interface-4a51ce54636f8443/inline0.js';
import { newSimulator } from './snippets/interface-4a51ce54636f8443/inline1.js';
import { completionKeymap, closeBracketsKeymap } from '@codemirror/autocomplete';
import { defaultKeymap, historyKeymap, indentWithTab } from '@codemirror/commands';
import { defaultHighlightStyle, foldKeymap } from '@codemirror/language';
import { searchKeymap } from '@codemirror/search';
import { Compartment, EditorState } from '@codemirror/state';
import { EditorView, keymap } from '@codemirror/view';
import { getParser } from 'bowser';

let wasm;
export function __wbg_set_wasm(val) {
    wasm = val;
}


const lTextDecoder = typeof TextDecoder === 'undefined' ? (0, module.require)('util').TextDecoder : TextDecoder;

let cachedTextDecoder = new lTextDecoder('utf-8', { ignoreBOM: true, fatal: true });

cachedTextDecoder.decode();

let cachedUint8Memory0 = null;

function getUint8Memory0() {
    if (cachedUint8Memory0 === null || cachedUint8Memory0.byteLength === 0) {
        cachedUint8Memory0 = new Uint8Array(wasm.memory.buffer);
    }
    return cachedUint8Memory0;
}

function getStringFromWasm0(ptr, len) {
    ptr = ptr >>> 0;
    return cachedTextDecoder.decode(getUint8Memory0().subarray(ptr, ptr + len));
}

function isLikeNone(x) {
    return x === undefined || x === null;
}

let cachedFloat64Memory0 = null;

function getFloat64Memory0() {
    if (cachedFloat64Memory0 === null || cachedFloat64Memory0.byteLength === 0) {
        cachedFloat64Memory0 = new Float64Array(wasm.memory.buffer);
    }
    return cachedFloat64Memory0;
}

let cachedInt32Memory0 = null;

function getInt32Memory0() {
    if (cachedInt32Memory0 === null || cachedInt32Memory0.byteLength === 0) {
        cachedInt32Memory0 = new Int32Array(wasm.memory.buffer);
    }
    return cachedInt32Memory0;
}

let WASM_VECTOR_LEN = 0;

const lTextEncoder = typeof TextEncoder === 'undefined' ? (0, module.require)('util').TextEncoder : TextEncoder;

let cachedTextEncoder = new lTextEncoder('utf-8');

const encodeString = (typeof cachedTextEncoder.encodeInto === 'function'
    ? function (arg, view) {
    return cachedTextEncoder.encodeInto(arg, view);
}
    : function (arg, view) {
    const buf = cachedTextEncoder.encode(arg);
    view.set(buf);
    return {
        read: arg.length,
        written: buf.length
    };
});

function passStringToWasm0(arg, malloc, realloc) {

    if (realloc === undefined) {
        const buf = cachedTextEncoder.encode(arg);
        const ptr = malloc(buf.length, 1) >>> 0;
        getUint8Memory0().subarray(ptr, ptr + buf.length).set(buf);
        WASM_VECTOR_LEN = buf.length;
        return ptr;
    }

    let len = arg.length;
    let ptr = malloc(len, 1) >>> 0;

    const mem = getUint8Memory0();

    let offset = 0;

    for (; offset < len; offset++) {
        const code = arg.charCodeAt(offset);
        if (code > 0x7F) break;
        mem[ptr + offset] = code;
    }

    if (offset !== len) {
        if (offset !== 0) {
            arg = arg.slice(offset);
        }
        ptr = realloc(ptr, len, len = offset + arg.length * 3, 1) >>> 0;
        const view = getUint8Memory0().subarray(ptr + offset, ptr + len);
        const ret = encodeString(arg, view);

        offset += ret.written;
        ptr = realloc(ptr, len, offset, 1) >>> 0;
    }

    WASM_VECTOR_LEN = offset;
    return ptr;
}

function debugString(val) {
    // primitive types
    const type = typeof val;
    if (type == 'number' || type == 'boolean' || val == null) {
        return  `${val}`;
    }
    if (type == 'string') {
        return `"${val}"`;
    }
    if (type == 'symbol') {
        const description = val.description;
        if (description == null) {
            return 'Symbol';
        } else {
            return `Symbol(${description})`;
        }
    }
    if (type == 'function') {
        const name = val.name;
        if (typeof name == 'string' && name.length > 0) {
            return `Function(${name})`;
        } else {
            return 'Function';
        }
    }
    // objects
    if (Array.isArray(val)) {
        const length = val.length;
        let debug = '[';
        if (length > 0) {
            debug += debugString(val[0]);
        }
        for(let i = 1; i < length; i++) {
            debug += ', ' + debugString(val[i]);
        }
        debug += ']';
        return debug;
    }
    // Test for built-in
    const builtInMatches = /\[object ([^\]]+)\]/.exec(toString.call(val));
    let className;
    if (builtInMatches.length > 1) {
        className = builtInMatches[1];
    } else {
        // Failed to match the standard '[object ClassName]'
        return toString.call(val);
    }
    if (className == 'Object') {
        // we're a user defined class or Object
        // JSON.stringify avoids problems with cycles, and is generally much
        // easier than looping through ownProperties of `val`.
        try {
            return 'Object(' + JSON.stringify(val) + ')';
        } catch (_) {
            return 'Object';
        }
    }
    // errors
    if (val instanceof Error) {
        return `${val.name}: ${val.message}\n${val.stack}`;
    }
    // TODO we could test for more things here, like `Set`s and `Map`s.
    return className;
}

const CLOSURE_DTORS = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(state => {
    wasm.__wbindgen_export_3.get(state.dtor)(state.a, state.b)
});

function makeMutClosure(arg0, arg1, dtor, f) {
    const state = { a: arg0, b: arg1, cnt: 1, dtor };
    const real = (...args) => {
        // First up with a closure we increment the internal reference
        // count. This ensures that the Rust closure environment won't
        // be deallocated while we're invoking it.
        state.cnt++;
        const a = state.a;
        state.a = 0;
        try {
            return f(a, state.b, ...args);
        } finally {
            if (--state.cnt === 0) {
                wasm.__wbindgen_export_3.get(state.dtor)(a, state.b);
                CLOSURE_DTORS.unregister(state);
            } else {
                state.a = a;
            }
        }
    };
    real.original = state;
    CLOSURE_DTORS.register(real, state, state);
    return real;
}
function __wbg_adapter_38(arg0, arg1) {
    wasm.wasm_bindgen__convert__closures__invoke0_mut__h2934327c8a6a54cc(arg0, arg1);
}

function __wbg_adapter_41(arg0, arg1, arg2) {
    wasm.closure75_externref_shim(arg0, arg1, arg2);
}

function makeClosure(arg0, arg1, dtor, f) {
    const state = { a: arg0, b: arg1, cnt: 1, dtor };
    const real = (...args) => {
        // First up with a closure we increment the internal reference
        // count. This ensures that the Rust closure environment won't
        // be deallocated while we're invoking it.
        state.cnt++;
        try {
            return f(state.a, state.b, ...args);
        } finally {
            if (--state.cnt === 0) {
                wasm.__wbindgen_export_3.get(state.dtor)(state.a, state.b);
                state.a = 0;
                CLOSURE_DTORS.unregister(state);
            }
        }
    };
    real.original = state;
    CLOSURE_DTORS.register(real, state, state);
    return real;
}
function __wbg_adapter_44(arg0, arg1, arg2) {
    wasm.closure34_externref_shim(arg0, arg1, arg2);
}

function __wbg_adapter_49(arg0, arg1, arg2, arg3) {
    wasm.wasm_bindgen__convert__closures__invoke2__h3b9503cd9fa69a98(arg0, arg1, arg2, arg3);
}

function __wbg_adapter_52(arg0, arg1, arg2) {
    wasm.wasm_bindgen__convert__closures__invoke1__h49cf0605b13ffe6c(arg0, arg1, arg2);
}

function __wbg_adapter_55(arg0, arg1, arg2) {
    wasm.wasm_bindgen__convert__closures__invoke1__h49cf0605b13ffe6c(arg0, arg1, arg2);
}

function __wbg_adapter_58(arg0, arg1, arg2, arg3, arg4) {
    const ret = wasm.wasm_bindgen__convert__closures__invoke3__hc5d6a41145bc24f0(arg0, arg1, arg2, arg3, arg4);
    return ret >>> 0;
}

function __wbg_adapter_61(arg0, arg1) {
    const ret = wasm._dyn_core__ops__function__Fn_____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__h8d19088ed6b276ba(arg0, arg1);
    return ret;
}

function __wbg_adapter_64(arg0, arg1, arg2, arg3) {
    const ret = wasm.wasm_bindgen__convert__closures__invoke2__hb2348b32b7364d9f(arg0, arg1, arg2, arg3);
    return ret >>> 0;
}

function __wbg_adapter_67(arg0, arg1, arg2) {
    const ret = wasm.wasm_bindgen__convert__closures__invoke1__hb3d0a1f2232cc5b6(arg0, arg1, arg2);
    return ret !== 0;
}

function takeFromExternrefTable0(idx) {
    const value = wasm.__wbindgen_export_2.get(idx);
    wasm.__externref_table_dealloc(idx);
    return value;
}
function __wbg_adapter_70(arg0, arg1, arg2, arg3, arg4, arg5) {
    try {
        const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
        wasm.wasm_bindgen__convert__closures__invoke4__hec241b108fecaa40(retptr, arg0, arg1, arg2, arg3, arg4, arg5);
        var r0 = getInt32Memory0()[retptr / 4 + 0];
        var r1 = getInt32Memory0()[retptr / 4 + 1];
        var r2 = getInt32Memory0()[retptr / 4 + 2];
        if (r2) {
            throw takeFromExternrefTable0(r1);
        }
        return takeFromExternrefTable0(r0);
    } finally {
        wasm.__wbindgen_add_to_stack_pointer(16);
    }
}

function __wbg_adapter_73(arg0, arg1, arg2, arg3, arg4, arg5) {
    wasm.wasm_bindgen__convert__closures__invoke4__h6ef932bbe828df5f(arg0, arg1, arg2, arg3, arg4, arg5);
}

function __wbg_adapter_76(arg0, arg1, arg2) {
    const ret = wasm.wasm_bindgen__convert__closures__invoke1__hf607f4f7f9eca542(arg0, arg1, arg2);
    return ret >>> 0;
}

function __wbg_adapter_79(arg0, arg1, arg2, arg3) {
    const ret = wasm.wasm_bindgen__convert__closures__invoke2__hb2348b32b7364d9f(arg0, arg1, arg2, arg3);
    return ret;
}

function __wbg_adapter_82(arg0, arg1) {
    wasm._dyn_core__ops__function__Fn_____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__hd22b587941fdcbfb(arg0, arg1);
}

function __wbg_adapter_85(arg0, arg1, arg2, arg3) {
    wasm.wasm_bindgen__convert__closures__invoke2__hc5e435b0ad5cf7d6(arg0, arg1, arg2, arg3);
}

function __wbg_adapter_88(arg0, arg1, arg2) {
    try {
        const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
        wasm.closure160_externref_shim(retptr, arg0, arg1, arg2);
        var r0 = getInt32Memory0()[retptr / 4 + 0];
        var r1 = getInt32Memory0()[retptr / 4 + 1];
        var r2 = getInt32Memory0()[retptr / 4 + 2];
        if (r2) {
            throw takeFromExternrefTable0(r1);
        }
        return takeFromExternrefTable0(r0);
    } finally {
        wasm.__wbindgen_add_to_stack_pointer(16);
    }
}

function __wbg_adapter_91(arg0, arg1, arg2, arg3, arg4) {
    wasm.wasm_bindgen__convert__closures__invoke3__h751dc0cc4430f380(arg0, arg1, arg2, arg3, arg4);
}

function __wbg_adapter_94(arg0, arg1) {
    const ret = wasm._dyn_core__ops__function__Fn_____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__h9ec38816755b4d61(arg0, arg1);
    return ret !== 0;
}

function __wbg_adapter_97(arg0, arg1, arg2, arg3, arg4) {
    try {
        const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
        wasm.wasm_bindgen__convert__closures__invoke3__h65c70699f1ab235d(retptr, arg0, arg1, arg2, arg3, arg4);
        var r0 = getInt32Memory0()[retptr / 4 + 0];
        var r1 = getInt32Memory0()[retptr / 4 + 1];
        var r2 = getInt32Memory0()[retptr / 4 + 2];
        if (r2) {
            throw takeFromExternrefTable0(r1);
        }
        return takeFromExternrefTable0(r0);
    } finally {
        wasm.__wbindgen_add_to_stack_pointer(16);
    }
}

function __wbg_adapter_100(arg0, arg1) {
    const ret = wasm._dyn_core__ops__function__Fn_____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__h5d6a7c45a445275e(arg0, arg1);
    return ret >>> 0;
}

function __wbg_adapter_103(arg0, arg1, arg2, arg3, arg4, arg5) {
    wasm.wasm_bindgen__convert__closures__invoke4__h6ef932bbe828df5f(arg0, arg1, arg2, arg3, arg4, arg5);
}

function __wbg_adapter_106(arg0, arg1, arg2) {
    wasm.closure189_externref_shim(arg0, arg1, arg2);
}

function addToExternrefTable0(obj) {
    const idx = wasm.__externref_table_alloc();
    wasm.__wbindgen_export_2.set(idx, obj);
    return idx;
}

function handleError(f, args) {
    try {
        return f.apply(this, args);
    } catch (e) {
        const idx = addToExternrefTable0(e);
        wasm.__wbindgen_exn_store(idx);
    }
}
/**
* @returns {Promise<void>}
*/
export function main() {
    wasm.main();
}

function getFromExternrefTable0(idx) { return wasm.__wbindgen_export_2.get(idx); }

function getCachedStringFromWasm0(ptr, len) {
    if (ptr === 0) {
        return getFromExternrefTable0(len);
    } else {
        return getStringFromWasm0(ptr, len);
    }
}

function notDefined(what) { return () => { throw new Error(`${what} is not defined`); }; }

const EnvProxyFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_envproxy_free(ptr >>> 0));
/**
*/
export class EnvProxy {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EnvProxy.prototype);
        obj.__wbg_ptr = ptr;
        EnvProxyFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EnvProxyFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_envproxy_free(ptr);
    }
    /**
    * @param {any} arg0
    * @param {any} property
    * @returns {any}
    */
    get(arg0, property) {
        const ret = wasm.envproxy_get(this.__wbg_ptr, arg0, property);
        return ret;
    }
}

const GotFuncProxyFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_gotfuncproxy_free(ptr >>> 0));
/**
*/
export class GotFuncProxy {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(GotFuncProxy.prototype);
        obj.__wbg_ptr = ptr;
        GotFuncProxyFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        GotFuncProxyFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_gotfuncproxy_free(ptr);
    }
    /**
    * @param {any} arg0
    * @param {any} property
    * @returns {any}
    */
    get(arg0, property) {
        const ret = wasm.gotfuncproxy_get(this.__wbg_ptr, arg0, property);
        return ret;
    }
}

const GotMemProxyFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_gotmemproxy_free(ptr >>> 0));
/**
*/
export class GotMemProxy {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(GotMemProxy.prototype);
        obj.__wbg_ptr = ptr;
        GotMemProxyFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        GotMemProxyFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_gotmemproxy_free(ptr);
    }
    /**
    * @param {any} arg0
    * @param {any} property
    * @returns {any}
    */
    get(arg0, property) {
        const ret = wasm.gotmemproxy_get(this.__wbg_ptr, arg0, property);
        return ret;
    }
}

const WasiProxyFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_wasiproxy_free(ptr >>> 0));
/**
*/
export class WasiProxy {

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        WasiProxyFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_wasiproxy_free(ptr);
    }
    /**
    * @param {any} _proxied_target
    * @param {any} property
    * @returns {any}
    */
    get(_proxied_target, property) {
        const ret = wasm.wasiproxy_get(this.__wbg_ptr, _proxied_target, property);
        return ret;
    }
}

export function __wbg_instanceof_HtmlElement_430cfa09315574cc(arg0) {
    let result;
    try {
        result = arg0 instanceof HTMLElement;
    } catch (_) {
        result = false;
    }
    const ret = result;
    return ret;
};

export function __wbg_classList_b75072943b838f29(arg0) {
    const ret = arg0.classList;
    return ret;
};

export function __wbg_style_b32d5cb9a6bd4720(arg0) {
    const ret = arg0.style;
    return ret;
};

export function __wbg_instanceof_SvgElement_77f14bbf9e93ebd3(arg0) {
    let result;
    try {
        result = arg0 instanceof SVGElement;
    } catch (_) {
        result = false;
    }
    const ret = result;
    return ret;
};

export function __wbg_instanceof_HtmlDivElement_e1a21eb4f18b1328(arg0) {
    let result;
    try {
        result = arg0 instanceof HTMLDivElement;
    } catch (_) {
        result = false;
    }
    const ret = result;
    return ret;
};

export function __wbg_setTimeout_75cb9b6991a4031d() { return handleError(function (arg0, arg1) {
    const ret = setTimeout(arg0, arg1);
    return ret;
}, arguments) };

export function __wbg_back_7fc3faa4dea72297() { return handleError(function (arg0) {
    arg0.back();
}, arguments) };

export function __wbg_setInterval_edede8e2124cbb00() { return handleError(function (arg0, arg1) {
    const ret = setInterval(arg0, arg1);
    return ret;
}, arguments) };

export function __wbg_setdata_4d5b377238fff97c(arg0, arg1, arg2) {
    var v0 = getCachedStringFromWasm0(arg1, arg2);
    arg0.data = v0;
};

export function __wbg_new_87d841e70661f6e9() {
    const ret = new Object();
    return ret;
};

export function __wbg_setscrollTop_5e92b31a5e13a324(arg0, arg1) {
    arg0.scrollTop = arg1;
};

export function __wbg_instanceof_HtmlSpanElement_2666ad79f730242c(arg0) {
    let result;
    try {
        result = arg0 instanceof HTMLSpanElement;
    } catch (_) {
        result = false;
    }
    const ret = result;
    return ret;
};

export function __wbindgen_number_new(arg0) {
    const ret = arg0;
    return ret;
};

export function __wbg_new_aaf6fa5a24e25a70(arg0) {
    const ret = new Date(arg0);
    return ret;
};

export function __wbg_getTimezoneOffset_98604170efd7a383(arg0) {
    const ret = arg0.getTimezoneOffset();
    return ret;
};

export function __wbg_hash_286eced2921b7b34(arg0, arg1) {
    const ret = arg1.hash;
    const ptr1 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len1 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len1;
    getInt32Memory0()[arg0 / 4 + 0] = ptr1;
};

export function __wbg_value_c93cb4b4d352228e(arg0, arg1) {
    const ret = arg1.value;
    const ptr1 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len1 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len1;
    getInt32Memory0()[arg0 / 4 + 0] = ptr1;
};

export function __wbg_checked_fae75426dd38619c(arg0) {
    const ret = arg0.checked;
    return ret;
};

export function __wbg_offsetX_72778aba0c4b5674(arg0) {
    const ret = arg0.offsetX;
    return ret;
};

export function __wbg_offsetY_a8d9605d7c28379d(arg0) {
    const ret = arg0.offsetY;
    return ret;
};

export function __wbg_dom_da3e51f576f54b29(arg0) {
    const ret = arg0.dom;
    return ret;
};

export function __wbg_deltaX_de18e6f358ab88cf(arg0) {
    const ret = arg0.deltaX;
    return ret;
};

export function __wbg_deltaY_50a026b7421f883d(arg0) {
    const ret = arg0.deltaY;
    return ret;
};

export function __wbg_deltaMode_b8290e36698673d0(arg0) {
    const ret = arg0.deltaMode;
    return ret;
};

export function __wbg_preventDefault_d2c7416966cb0632(arg0) {
    arg0.preventDefault();
};

export function __wbg_terminate_e377ed5ee96f708d(arg0) {
    arg0.terminate();
};

export const __wbg_clearTimeout_541ac0980ffcef74 = typeof clearTimeout == 'function' ? clearTimeout : notDefined('clearTimeout');

export const __wbg_clearTimeout_76877dbc010e786d = typeof clearTimeout == 'function' ? clearTimeout : notDefined('clearTimeout');

export const __wbg_clearInterval_bd072ecb096d9775 = typeof clearInterval == 'function' ? clearInterval : notDefined('clearInterval');

export function __wbg_new_ace717933ad7117f(arg0) {
    const ret = new Uint8Array(arg0);
    return ret;
};

export function __wbg_buffer_3da2aecfd9814cd8(arg0) {
    const ret = arg0.buffer;
    return ret;
};

export function __wbg_of_3d7aa62bb0ab56ee(arg0) {
    const ret = Array.of(arg0);
    return ret;
};

export function __wbg_postMessage_f1efb42179877b63() { return handleError(function (arg0, arg1, arg2) {
    arg0.postMessage(arg1, arg2);
}, arguments) };

export function __wbg_shift_7bda7eba309c9a1f(arg0) {
    const ret = arg0.shift();
    return ret;
};

export function __wbg_instanceof_ArrayBuffer_f4521cec1b99ee35(arg0) {
    let result;
    try {
        result = arg0 instanceof ArrayBuffer;
    } catch (_) {
        result = false;
    }
    const ret = result;
    return ret;
};

export function __wbg_unshift_4671e53be53e8e39(arg0, arg1) {
    const ret = arg0.unshift(arg1);
    return ret;
};

export function __wbg_new_34c624469fb1d4fd() {
    const ret = new Array();
    return ret;
};

export function __wbg_instanceof_MessagePort_7f9c5eafc68453af(arg0) {
    let result;
    try {
        result = arg0 instanceof MessagePort;
    } catch (_) {
        result = false;
    }
    const ret = result;
    return ret;
};

export function __wbg_setTimeout_7d81d052875b0f4f() { return handleError(function (arg0, arg1) {
    const ret = setTimeout(arg0, arg1);
    return ret;
}, arguments) };

export function __wbg_data_5d6c23170bc379b2(arg0) {
    const ret = arg0.data;
    return ret;
};

export function __wbg_isArray_fbd24d447869b527(arg0) {
    const ret = Array.isArray(arg0);
    return ret;
};

export function __wbindgen_string_new(arg0, arg1) {
    const ret = getStringFromWasm0(arg0, arg1);
    return ret;
};

export function __wbg_document_5257b70811e953c0(arg0) {
    const ret = arg0.document;
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
};

export function __wbg_settype_f631a46ccbd4903c(arg0, arg1, arg2) {
    var v0 = getCachedStringFromWasm0(arg1, arg2);
    arg0.type = v0;
};

export function __wbg_head_267359b89c3f0368(arg0) {
    const ret = arg0.head;
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
};

export function __wbg_sheet_d0a6c2b24ad2cc4a(arg0) {
    const ret = arg0.sheet;
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
};

export function __wbg_cssRules_4e4ab1711604c586() { return handleError(function (arg0) {
    const ret = arg0.cssRules;
    return ret;
}, arguments) };

export function __wbg_length_7b18177a30b08d57(arg0) {
    const ret = arg0.length;
    return ret;
};

export function __wbg_insertRule_122c6767ff1c8957() { return handleError(function (arg0, arg1, arg2, arg3) {
    var v0 = getCachedStringFromWasm0(arg1, arg2);
    const ret = arg0.insertRule(v0, arg3 >>> 0);
    return ret;
}, arguments) };

export function __wbg_get_96a97e07b909c101(arg0, arg1) {
    const ret = arg0[arg1 >>> 0];
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
};

export function __wbg_style_dc9e8fddbfbf2a95(arg0) {
    const ret = arg0.style;
    return ret;
};

export function __wbindgen_number_get(arg0, arg1) {
    const obj = arg1;
    const ret = typeof(obj) === 'number' ? obj : undefined;
    getFloat64Memory0()[arg0 / 8 + 1] = isLikeNone(ret) ? 0 : ret;
    getInt32Memory0()[arg0 / 4 + 0] = !isLikeNone(ret);
};

export function __wbg_clientY_ea543e0b8dc1490d(arg0) {
    const ret = arg0.clientY;
    return ret;
};

export function __wbg_clientX_4d37584813a1790a(arg0) {
    const ret = arg0.clientX;
    return ret;
};

export function __wbg_stopPropagation_786ab850031995e5(arg0) {
    arg0.stopPropagation();
};

export function __wbg_new_3ff7ff424b10235d() { return handleError(function () {
    const ret = new FormData();
    return ret;
}, arguments) };

export function __wbg_instanceof_HtmlInputElement_d53941bc0aaa6ae9(arg0) {
    let result;
    try {
        result = arg0 instanceof HTMLInputElement;
    } catch (_) {
        result = false;
    }
    const ret = result;
    return ret;
};

export function __wbg_instanceof_HtmlButtonElement_2e288583ed44f37c(arg0) {
    let result;
    try {
        result = arg0 instanceof HTMLButtonElement;
    } catch (_) {
        result = false;
    }
    const ret = result;
    return ret;
};

export function __wbg_instanceof_HtmlCanvasElement_a6076360513b6876(arg0) {
    let result;
    try {
        result = arg0 instanceof HTMLCanvasElement;
    } catch (_) {
        result = false;
    }
    const ret = result;
    return ret;
};

export function __wbg_setProperty_a763529f4ef8ac76() { return handleError(function (arg0, arg1, arg2, arg3, arg4) {
    var v0 = getCachedStringFromWasm0(arg1, arg2);
    var v1 = getCachedStringFromWasm0(arg3, arg4);
    arg0.setProperty(v0, v1);
}, arguments) };

export function __wbg_transferControlToOffscreen_99f1bd2405a09a36() { return handleError(function (arg0) {
    const ret = arg0.transferControlToOffscreen();
    return ret;
}, arguments) };

export function __wbg_newEngine_2bb060fa9b98a2cb() { return handleError(function () {
    const ret = newEngine();
    return ret;
}, arguments) };

export function __wbg_newSimulator_2bb060fa9b98a2cb() { return handleError(function () {
    const ret = newSimulator();
    return ret;
}, arguments) };

export function __wbg_new_ad4df4628315a892() {
    const ret = new Map();
    return ret;
};

export function __wbg_set_83e83bc2428e50ab(arg0, arg1, arg2) {
    const ret = arg0.set(arg1, arg2);
    return ret;
};

export function __wbindgen_memory() {
    const ret = wasm.memory;
    return ret;
};

export function __wbg_buffer_5d1b598a01b41a42(arg0) {
    const ret = arg0.buffer;
    return ret;
};

export function __wbg_newwithbyteoffsetandlength_d695c7957788f922(arg0, arg1, arg2) {
    const ret = new Uint8Array(arg0, arg1 >>> 0, arg2 >>> 0);
    return ret;
};

export function __wbg_randomFillSync_b70ccbdf4926a99d() { return handleError(function (arg0, arg1) {
    arg0.randomFillSync(arg1);
}, arguments) };

export function __wbg_subarray_7f7a652672800851(arg0, arg1, arg2) {
    const ret = arg0.subarray(arg1 >>> 0, arg2 >>> 0);
    return ret;
};

export function __wbg_getRandomValues_7e42b4fb8779dc6d() { return handleError(function (arg0, arg1) {
    arg0.getRandomValues(arg1);
}, arguments) };

export function __wbg_new_77893023789a494c() {
    const ret = new Compartment();
    return ret;
};

export function __wbg_static_accessor_DEFAULT_HIGHLIGHT_STYLE_5dc157d3bfcfc49c() {
    const ret = defaultHighlightStyle;
    return ret;
};

export function __wbg_static_accessor_KEYMAP_1f943cb4cc94a942() {
    const ret = keymap;
    return ret;
};

export function __wbg_concat_5170b7ad7d090732(arg0, arg1) {
    const ret = arg0.concat(arg1);
    return ret;
};

export function __wbg_static_accessor_IDENT_WITH_TAB_f986b4e92893e344() {
    const ret = indentWithTab;
    return ret;
};

export function __wbg_of_ec81257e328f63dc(arg0, arg1) {
    const ret = arg0.of(arg1);
    return ret;
};

export function __wbg_updatelistener_e14554ebcabeffe3() {
    const ret = EditorView.updateListener;
    return ret;
};

export function __wbg_of_5082613e3aa3ff01(arg0, arg1) {
    const ret = arg0.of(arg1);
    return ret;
};

export function __wbg_push_906164999551d793(arg0, arg1) {
    const ret = arg0.push(arg1);
    return ret;
};

export function __wbg_create_e90a6b476e067a2a(arg0) {
    const ret = EditorState.create(arg0);
    return ret;
};

export function __wbg_new_617ce75433051891(arg0) {
    const ret = new EditorView(arg0);
    return ret;
};

export function __wbg_navigator_910cca0226b70083(arg0) {
    const ret = arg0.navigator;
    return ret;
};

export function __wbg_userAgent_4106f80b9924b065() { return handleError(function (arg0, arg1) {
    const ret = arg1.userAgent;
    const ptr1 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len1 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len1;
    getInt32Memory0()[arg0 / 4 + 0] = ptr1;
}, arguments) };

export function __wbg_getParser_4010584cea831d04(arg0, arg1, arg2) {
    var v0 = getCachedStringFromWasm0(arg0, arg1);
    const ret = getParser(v0, arg2 !== 0);
    return ret;
};

export function __wbg_getEngineName_4e8ba66ed2b64f9b(arg0, arg1) {
    const ret = arg1.getEngineName();
    const ptr1 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len1 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len1;
    getInt32Memory0()[arg0 / 4 + 0] = ptr1;
};

export function __wbg_getPlatformType_ca3553bbc3101278(arg0, arg1) {
    const ret = arg1.getPlatformType();
    const ptr1 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len1 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len1;
    getInt32Memory0()[arg0 / 4 + 0] = ptr1;
};

export function __wbg_new_a979e9eedc5e81a3() { return handleError(function () {
    const ret = new Headers();
    return ret;
}, arguments) };

export function __wbg_new_26bb7e688dfc365c() { return handleError(function () {
    const ret = new URLSearchParams();
    return ret;
}, arguments) };

export function __wbg_newwithstr_19bf69d1840d2816() { return handleError(function (arg0, arg1) {
    var v0 = getCachedStringFromWasm0(arg0, arg1);
    const ret = new Request(v0);
    return ret;
}, arguments) };

export function __wbg_url_70f3179afe0eccd6(arg0, arg1) {
    const ret = arg1.url;
    const ptr1 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len1 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len1;
    getInt32Memory0()[arg0 / 4 + 0] = ptr1;
};

export function __wbg_search_b5c7b044aaf64616(arg0, arg1) {
    const ret = arg1.search;
    const ptr1 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len1 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len1;
    getInt32Memory0()[arg0 / 4 + 0] = ptr1;
};

export function __wbg_setsearch_ad0620e22e67a913(arg0, arg1, arg2) {
    var v0 = getCachedStringFromWasm0(arg1, arg2);
    arg0.search = v0;
};

export function __wbg_toString_61d1ba76c783d2bc(arg0) {
    const ret = arg0.toString();
    return ret;
};

export function __wbg_newwithstrandinit_9fd2fc855c6327eb() { return handleError(function (arg0, arg1, arg2) {
    var v0 = getCachedStringFromWasm0(arg0, arg1);
    const ret = new Request(v0, arg2);
    return ret;
}, arguments) };

export function __wbg_get_5027b32da70f39b1() { return handleError(function (arg0, arg1) {
    const ret = Reflect.get(arg0, arg1);
    return ret;
}, arguments) };

export function __wbindgen_is_undefined(arg0) {
    const ret = arg0 === undefined;
    return ret;
};

export function __wbg_instanceof_Window_99dc9805eaa2614b(arg0) {
    let result;
    try {
        result = arg0 instanceof Window;
    } catch (_) {
        result = false;
    }
    const ret = result;
    return ret;
};

export function __wbg_fetch_ba9c8f5d941ae5c4(arg0, arg1) {
    const ret = arg0.fetch(arg1);
    return ret;
};

export function __wbg_instanceof_WorkerGlobalScope_a9d2cb51ce9a4579(arg0) {
    let result;
    try {
        result = arg0 instanceof WorkerGlobalScope;
    } catch (_) {
        result = false;
    }
    const ret = result;
    return ret;
};

export function __wbg_fetch_06d656a1b748ac0d(arg0, arg1) {
    const ret = arg0.fetch(arg1);
    return ret;
};

export function __wbg_instanceof_Response_0d25bb8436a9cefe(arg0) {
    let result;
    try {
        result = arg0 instanceof Response;
    } catch (_) {
        result = false;
    }
    const ret = result;
    return ret;
};

export function __wbg_docchanged_2471af6026d28d11(arg0) {
    const ret = arg0.docChanged;
    return ret;
};

export function __wbg_state_1ae57365c4b56355(arg0) {
    const ret = arg0.state;
    return ret;
};

export function __wbg_doc_85f4a27b12de29b0(arg0) {
    const ret = arg0.doc;
    return ret;
};

export function __wbg_toString_097acbf8a534624d(arg0, arg1) {
    const ret = arg1.toString();
    const ptr1 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len1 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len1;
    getInt32Memory0()[arg0 / 4 + 0] = ptr1;
};

export function __wbg_button_8a97c55db17c7314(arg0) {
    const ret = arg0.button;
    return ret;
};

export function __wbg_static_accessor_COMPLETION_KEYMAP_7cf0ae43e68f0922() {
    const ret = completionKeymap;
    return ret;
};

export function __wbg_static_accessor_CLOSE_BRACKETS_KEYMAP_daa68aa19df62a2b() {
    const ret = closeBracketsKeymap;
    return ret;
};

export function __wbg_static_accessor_DEFAULT_KEYMAP_6de06c2c0c4b7700() {
    const ret = defaultKeymap;
    return ret;
};

export function __wbg_static_accessor_HISTORY_KEYMAP_31b14a17a5ba2d42() {
    const ret = historyKeymap;
    return ret;
};

export function __wbg_static_accessor_FOLD_KEYMAP_70476de5d62d1811() {
    const ret = foldKeymap;
    return ret;
};

export function __wbg_static_accessor_SEARCH_KEYMAP_7d4ee2734c9f6a27() {
    const ret = searchKeymap;
    return ret;
};

export function __wbg_new_abda76e883ba8a5f() {
    const ret = new Error();
    return ret;
};

export function __wbg_stack_658279fe44541cf6(arg0, arg1) {
    const ret = arg1.stack;
    const ptr1 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len1 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len1;
    getInt32Memory0()[arg0 / 4 + 0] = ptr1;
};

export function __wbg_error_f851667af71bcfc6(arg0, arg1) {
    var v0 = getCachedStringFromWasm0(arg0, arg1);
if (arg0 !== 0) { wasm.__wbindgen_free(arg0, arg1, 1); }
console.error(v0);
};

export function __wbg_setProperty_8e45890b25dfbebf() { return handleError(function (arg0, arg1, arg2, arg3, arg4, arg5, arg6) {
    var v0 = getCachedStringFromWasm0(arg1, arg2);
    var v1 = getCachedStringFromWasm0(arg3, arg4);
    var v2 = getCachedStringFromWasm0(arg5, arg6);
    arg0.setProperty(v0, v1, v2);
}, arguments) };

export function __wbg_getPropertyValue_9f0d67e1a114f89a() { return handleError(function (arg0, arg1, arg2, arg3) {
    var v0 = getCachedStringFromWasm0(arg2, arg3);
    const ret = arg1.getPropertyValue(v0);
    const ptr2 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len2 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len2;
    getInt32Memory0()[arg0 / 4 + 0] = ptr2;
}, arguments) };

export function __wbg_removeProperty_569b8c8469084b23() { return handleError(function (arg0, arg1, arg2, arg3) {
    var v0 = getCachedStringFromWasm0(arg2, arg3);
    const ret = arg1.removeProperty(v0);
    const ptr2 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len2 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len2;
    getInt32Memory0()[arg0 / 4 + 0] = ptr2;
}, arguments) };

export function __wbg_location_0f233324e8e8c699(arg0) {
    const ret = arg0.location;
    return ret;
};

export function __wbg_href_1ab7f03b8a745310() { return handleError(function (arg0, arg1) {
    const ret = arg1.href;
    const ptr1 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len1 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len1;
    getInt32Memory0()[arg0 / 4 + 0] = ptr1;
}, arguments) };

export function __wbindgen_cb_drop(arg0) {
    const obj = arg0.original;
    if (obj.cnt-- == 1) {
        obj.a = 0;
        return true;
    }
    const ret = false;
    return ret;
};

export function __wbg_pushState_90b0a1cf59505502() { return handleError(function (arg0, arg1, arg2, arg3, arg4, arg5) {
    var v0 = getCachedStringFromWasm0(arg2, arg3);
    var v1 = getCachedStringFromWasm0(arg4, arg5);
    arg0.pushState(arg1, v0, v1);
}, arguments) };

export function __wbg_createElementNS_d47e0c50fa2904e0() { return handleError(function (arg0, arg1, arg2, arg3, arg4) {
    var v0 = getCachedStringFromWasm0(arg1, arg2);
    var v1 = getCachedStringFromWasm0(arg3, arg4);
    const ret = arg0.createElementNS(v0, v1);
    return ret;
}, arguments) };

export function __wbg_createTextNode_dbdd908f92bae1b1(arg0, arg1, arg2) {
    var v0 = getCachedStringFromWasm0(arg1, arg2);
    const ret = arg0.createTextNode(v0);
    return ret;
};

export function __wbg_createComment_ce9f467394242d45(arg0, arg1, arg2) {
    var v0 = getCachedStringFromWasm0(arg1, arg2);
    const ret = arg0.createComment(v0);
    return ret;
};

export function __wbg_setAttribute_0918ea45d5a1c663() { return handleError(function (arg0, arg1, arg2, arg3, arg4) {
    var v0 = getCachedStringFromWasm0(arg1, arg2);
    var v1 = getCachedStringFromWasm0(arg3, arg4);
    arg0.setAttribute(v0, v1);
}, arguments) };

export function __wbg_add_44212bfb95df48ba() { return handleError(function (arg0, arg1, arg2) {
    var v0 = getCachedStringFromWasm0(arg1, arg2);
    arg0.add(v0);
}, arguments) };

export function __wbg_remove_0df84ff63b459921() { return handleError(function (arg0, arg1, arg2) {
    var v0 = getCachedStringFromWasm0(arg1, arg2);
    arg0.remove(v0);
}, arguments) };

export function __wbg_removeChild_14b08321b677677a() { return handleError(function (arg0, arg1) {
    const ret = arg0.removeChild(arg1);
    return ret;
}, arguments) };

export function __wbg_insertBefore_882082ef4c5d7766() { return handleError(function (arg0, arg1, arg2) {
    const ret = arg0.insertBefore(arg1, arg2);
    return ret;
}, arguments) };

export function __wbg_replaceChild_6da761aae27deb61() { return handleError(function (arg0, arg1, arg2) {
    const ret = arg0.replaceChild(arg1, arg2);
    return ret;
}, arguments) };

export function __wbg_body_3eb73da919b867a1(arg0) {
    const ret = arg0.body;
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
};

export function __wbg_crypto_d05b68a3572bb8ca(arg0) {
    const ret = arg0.crypto;
    return ret;
};

export function __wbindgen_is_object(arg0) {
    const val = arg0;
    const ret = typeof(val) === 'object' && val !== null;
    return ret;
};

export function __wbg_process_b02b3570280d0366(arg0) {
    const ret = arg0.process;
    return ret;
};

export function __wbg_versions_c1cb42213cedf0f5(arg0) {
    const ret = arg0.versions;
    return ret;
};

export function __wbg_node_43b1089f407e4ec2(arg0) {
    const ret = arg0.node;
    return ret;
};

export function __wbindgen_is_string(arg0) {
    const ret = typeof(arg0) === 'string';
    return ret;
};

export function __wbg_require_9a7e0f667ead4995() { return handleError(function () {
    const ret = module.require;
    return ret;
}, arguments) };

export function __wbindgen_is_function(arg0) {
    const ret = typeof(arg0) === 'function';
    return ret;
};

export function __wbg_call_f6a2bc58c19c53c6() { return handleError(function (arg0, arg1, arg2) {
    const ret = arg0.call(arg1, arg2);
    return ret;
}, arguments) };

export function __wbg_msCrypto_10fc94afee92bd76(arg0) {
    const ret = arg0.msCrypto;
    return ret;
};

export function __wbg_newwithlength_728575f3bba9959b(arg0) {
    const ret = new Uint8Array(arg0 >>> 0);
    return ret;
};

export function __wbg_instanceof_Error_f5ae6a28929a8190(arg0) {
    let result;
    try {
        result = arg0 instanceof Error;
    } catch (_) {
        result = false;
    }
    const ret = result;
    return ret;
};

export function __wbg_name_90a0336d27b12317(arg0) {
    const ret = arg0.name;
    return ret;
};

export function __wbg_message_5dbdf59ed61bbc49(arg0) {
    const ret = arg0.message;
    return ret;
};

export function __wbg_toString_5326377607a05bf2(arg0) {
    const ret = arg0.toString();
    return ret;
};

export function __wbg_next_267398d0e0761bf9() { return handleError(function (arg0) {
    const ret = arg0.next();
    return ret;
}, arguments) };

export function __wbg_done_506b44765ba84b9c(arg0) {
    const ret = arg0.done;
    return ret;
};

export function __wbg_value_31485d8770eb06ab(arg0) {
    const ret = arg0.value;
    return ret;
};

export function __wbindgen_string_get(arg0, arg1) {
    const obj = arg1;
    const ret = typeof(obj) === 'string' ? obj : undefined;
    var ptr1 = isLikeNone(ret) ? 0 : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    var len1 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len1;
    getInt32Memory0()[arg0 / 4 + 0] = ptr1;
};

export function __wbg_self_086b5302bcafb962() { return handleError(function () {
    const ret = self.self;
    return ret;
}, arguments) };

export function __wbg_window_132fa5d7546f1de5() { return handleError(function () {
    const ret = window.window;
    return ret;
}, arguments) };

export function __wbg_globalThis_e5f801a37ad7d07b() { return handleError(function () {
    const ret = globalThis.globalThis;
    return ret;
}, arguments) };

export function __wbg_global_f9a61fce4af6b7c1() { return handleError(function () {
    const ret = global.global;
    return ret;
}, arguments) };

export function __wbg_newnoargs_5859b6d41c6fe9f7(arg0, arg1) {
    var v0 = getCachedStringFromWasm0(arg0, arg1);
    const ret = new Function(v0);
    return ret;
};

export function __wbg_call_a79f1973a4f07d5e() { return handleError(function (arg0, arg1) {
    const ret = arg0.call(arg1);
    return ret;
}, arguments) };

export function __wbg_set_37a50e901587b477() { return handleError(function (arg0, arg1, arg2) {
    const ret = Reflect.set(arg0, arg1, arg2);
    return ret;
}, arguments) };

export function __wbg_set_74906aa30864df5a(arg0, arg1, arg2) {
    arg0.set(arg1, arg2 >>> 0);
};

export function __wbg_length_f0764416ba5bb237(arg0) {
    const ret = arg0.length;
    return ret;
};

export const __wbg_random_07b2df1a129f4f17 = typeof Math.random == 'function' ? Math.random : notDefined('Math.random');

export function __wbg_new_ad731e6c8cecb940(arg0) {
    const ret = new Int32Array(arg0);
    return ret;
};

export function __wbg_waitAsync_2151fdbb582576c7() { return handleError(function (arg0, arg1, arg2) {
    const ret = Atomics.waitAsync(arg0, arg1 >>> 0, arg2);
    return ret;
}, arguments) };

export function __wbg_store_3b88fdd05b11b2f4() { return handleError(function (arg0, arg1, arg2) {
    const ret = Atomics.store(arg0, arg1 >>> 0, arg2);
    return ret;
}, arguments) };

export function __wbg_instanceof_Promise_bbd46d56519fb966(arg0) {
    let result;
    try {
        result = arg0 instanceof Promise;
    } catch (_) {
        result = false;
    }
    const ret = result;
    return ret;
};

export function __wbg_new_8be2c974d27d6540(arg0) {
    const ret = new Uint32Array(arg0);
    return ret;
};

export function __wbg_getindex_b53650d0f6728640(arg0, arg1) {
    const ret = arg0[arg1 >>> 0];
    return ret;
};

export function __wbg_slice_68a7b6c56027340a(arg0, arg1, arg2) {
    const ret = arg0.slice(arg1 >>> 0, arg2 >>> 0);
    return ret;
};

export function __wbg_new_393d9e609f8faae8() { return handleError(function (arg0) {
    const ret = new WebAssembly.Module(arg0);
    return ret;
}, arguments) };

export const __wbg_compile_e698742d842b9f89 = typeof WebAssembly.compile == 'function' ? WebAssembly.compile : notDefined('WebAssembly.compile');

export function __wbg_getindex_0462906f06036feb(arg0, arg1) {
    const ret = arg0[arg1 >>> 0];
    return ret;
};

export function __wbg_fill_ed90af5e9a810d6b(arg0, arg1, arg2, arg3) {
    const ret = arg0.fill(arg1, arg2 >>> 0, arg3 >>> 0);
    return ret;
};

export function __wbg_length_0c5d20eba514ad27(arg0) {
    const ret = arg0.length;
    return ret;
};

export function __wbg_setindex_20460e6d055b25ca(arg0, arg1, arg2) {
    arg0[arg1 >>> 0] = arg2;
};

export function __wbg_setindex_bb9a17757fb5089c(arg0, arg1, arg2) {
    arg0[arg1 >>> 0] = arg2 >>> 0;
};

export function __wbg_grow_69302b85cfc290e3() { return handleError(function (arg0, arg1) {
    const ret = arg0.grow(arg1 >>> 0);
    return ret;
}, arguments) };

export function __wbg_new_c50681e5736aeae8() { return handleError(function (arg0, arg1) {
    const ret = new WebAssembly.Global(arg0, arg1);
    return ret;
}, arguments) };

export function __wbg_envproxy_new(arg0) {
    const ret = EnvProxy.__wrap(arg0);
    return ret;
};

export function __wbg_instanceof_Object_06e0ec0f1056bcd5(arg0) {
    let result;
    try {
        result = arg0 instanceof Object;
    } catch (_) {
        result = false;
    }
    const ret = result;
    return ret;
};

export function __wbg_bind_ec554c9733f96765(arg0, arg1) {
    const ret = arg0.bind(arg1);
    return ret;
};

export function __wbg_new_145b4fea3cafc601(arg0, arg1) {
    const ret = new Proxy(arg0, arg1);
    return ret;
};

export function __wbg_gotmemproxy_new(arg0) {
    const ret = GotMemProxy.__wrap(arg0);
    return ret;
};

export function __wbg_gotfuncproxy_new(arg0) {
    const ret = GotFuncProxy.__wrap(arg0);
    return ret;
};

export function __wbg_new_c8841ffc76bb8f0e() { return handleError(function (arg0, arg1) {
    const ret = new WebAssembly.Instance(arg0, arg1);
    return ret;
}, arguments) };

export const __wbg_instantiate_4969234dd921d59a = typeof WebAssembly.instantiate == 'function' ? WebAssembly.instantiate : notDefined('WebAssembly.instantiate');

export function __wbg_get_ca324e8b9d32bbb3() { return handleError(function (arg0, arg1) {
    const ret = arg0.get(arg1 >>> 0);
    return ret;
}, arguments) };

export function __wbindgen_is_null(arg0) {
    const ret = arg0 === null;
    return ret;
};

export function __wbg_set_608725b060eff00f(arg0, arg1, arg2) {
    const ret = arg0.set(arg1, arg2);
    return ret;
};

export function __wbg_exports_f39b8faa22512b2b(arg0) {
    const ret = arg0.exports;
    return ret;
};

export function __wbg_entries_7a47f5716366056b(arg0) {
    const ret = Object.entries(arg0);
    return ret;
};

export function __wbg_values_705f0abfa0ca41f6(arg0) {
    const ret = arg0.values();
    return ret;
};

export function __wbg_values_2415d2dc53bb82f7(arg0) {
    const ret = Object.values(arg0);
    return ret;
};

export function __wbg_instanceof_Global_9f335e05e259aead(arg0) {
    let result;
    try {
        result = arg0 instanceof Global;
    } catch (_) {
        result = false;
    }
    const ret = result;
    return ret;
};

export function __wbg_value_3ec6fba02c5d720e(arg0) {
    const ret = arg0.value;
    return ret;
};

export function __wbindgen_is_falsy(arg0) {
    const ret = !arg0;
    return ret;
};

export function __wbg_setvalue_f2d61c408b793101(arg0, arg1) {
    arg0.value = arg1;
};

export function __wbg_customSections_9f9871398b2ad791(arg0, arg1, arg2) {
    var v0 = getCachedStringFromWasm0(arg1, arg2);
    const ret = WebAssembly.Module.customSections(arg0, v0);
    return ret;
};

export function __wbg_get_0a2c1a6b91ea556c(arg0, arg1) {
    const ret = arg0.get(arg1);
    return ret;
};

export function __wbg_set_b4585b071bc2a64d() { return handleError(function (arg0, arg1, arg2) {
    arg0.set(arg1 >>> 0, arg2);
}, arguments) };

export function __wbg_now_86f7ca537c8b86d5() {
    const ret = Date.now();
    return ret;
};

export function __wbg_bind_3dc1c2b6f6a7d461(arg0, arg1, arg2, arg3) {
    const ret = arg0.bind(arg1, arg2, arg3);
    return ret;
};

export function __wbg_call_84a3935b24cb0625() { return handleError(function (arg0, arg1, arg2, arg3, arg4) {
    const ret = arg0.call(arg1, arg2, arg3, arg4);
    return ret;
}, arguments) };

export function __wbg_timeOrigin_03715741c9ab035d(arg0) {
    const ret = arg0.timeOrigin;
    return ret;
};

export function __wbg_now_65ff8ec2b863300c(arg0) {
    const ret = arg0.now();
    return ret;
};

export function __wbg_apply_d890a2eae27f4a03() { return handleError(function (arg0, arg1, arg2) {
    const ret = arg0.apply(arg1, arg2);
    return ret;
}, arguments) };

export function __wbg_grow_43df94948cf95609(arg0, arg1) {
    const ret = arg0.grow(arg1 >>> 0);
    return ret;
};

export function __wbg_call_5830eb5e103aa772() { return handleError(function (arg0, arg1, arg2, arg3) {
    const ret = arg0.call(arg1, arg2, arg3);
    return ret;
}, arguments) };

export function __wbg_navigator_fbab58a088920616(arg0) {
    const ret = arg0.navigator;
    return ret;
};

export function __wbg_userAgent_a334df34d9cf596f() { return handleError(function (arg0, arg1) {
    const ret = arg1.userAgent;
    const ptr1 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len1 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len1;
    getInt32Memory0()[arg0 / 4 + 0] = ptr1;
}, arguments) };

export function __wbg_fetch_0117c27c9b3739e0(arg0, arg1, arg2) {
    var v0 = getCachedStringFromWasm0(arg1, arg2);
    const ret = arg0.fetch(v0);
    return ret;
};

export function __wbg_fetch_acb234cb2d211bf3(arg0, arg1, arg2) {
    var v0 = getCachedStringFromWasm0(arg1, arg2);
    const ret = arg0.fetch(v0);
    return ret;
};

export const __wbg_compileStreaming_992dd1ed22d0a1be = typeof WebAssembly.compileStreaming == 'function' ? WebAssembly.compileStreaming : notDefined('WebAssembly.compileStreaming');

export function __wbg_instanceof_DedicatedWorkerGlobalScope_3e325e5a460a9ee6(arg0) {
    let result;
    try {
        result = arg0 instanceof DedicatedWorkerGlobalScope;
    } catch (_) {
        result = false;
    }
    const ret = result;
    return ret;
};

export const __wbg_error_1f4e3e298a7c97f6 = typeof console.error == 'function' ? console.error : notDefined('console.error');

export const __wbg_debug_81bf1b6b83cc1a06 = typeof console.debug == 'function' ? console.debug : notDefined('console.debug');

export const __wbg_info_24b7c0f9d7eb6623 = typeof console.info == 'function' ? console.info : notDefined('console.info');

export const __wbg_warn_0e0204547af47087 = typeof console.warn == 'function' ? console.warn : notDefined('console.warn');

export function __wbindgen_debug_string(arg0, arg1) {
    const ret = debugString(arg1);
    const ptr1 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len1 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len1;
    getInt32Memory0()[arg0 / 4 + 0] = ptr1;
};

export function __wbindgen_throw(arg0, arg1) {
    throw new Error(getStringFromWasm0(arg0, arg1));
};

export function __wbindgen_rethrow(arg0) {
    throw arg0;
};

export function __wbg_then_7aeb7c5f1536640f(arg0, arg1) {
    const ret = arg0.then(arg1);
    return ret;
};

export const __wbg_queueMicrotask_118eeb525d584d9a = typeof queueMicrotask == 'function' ? queueMicrotask : notDefined('queueMicrotask');

export function __wbg_then_5842e4e97f7beace(arg0, arg1, arg2) {
    const ret = arg0.then(arg1, arg2);
    return ret;
};

export function __wbg_queueMicrotask_26a89c14c53809c0(arg0) {
    const ret = arg0.queueMicrotask;
    return ret;
};

export function __wbg_resolve_97ecd55ee839391b(arg0) {
    const ret = Promise.resolve(arg0);
    return ret;
};

export function __wbg_createElement_1a136faad4101f43() { return handleError(function (arg0, arg1, arg2) {
    var v0 = getCachedStringFromWasm0(arg1, arg2);
    const ret = arg0.createElement(v0);
    return ret;
}, arguments) };

export function __wbg_addEventListener_1b158e9e95e0ab00() { return handleError(function (arg0, arg1, arg2, arg3, arg4) {
    var v0 = getCachedStringFromWasm0(arg1, arg2);
    arg0.addEventListener(v0, arg3, arg4);
}, arguments) };

export function __wbg_removeEventListener_177ff96081e6f22d() { return handleError(function (arg0, arg1, arg2, arg3, arg4) {
    var v0 = getCachedStringFromWasm0(arg1, arg2);
    arg0.removeEventListener(v0, arg3, arg4 !== 0);
}, arguments) };

export function __wbg_append_6ee425c15561b5cc() { return handleError(function (arg0, arg1, arg2, arg3, arg4) {
    var v0 = getCachedStringFromWasm0(arg1, arg2);
    var v1 = getCachedStringFromWasm0(arg3, arg4);
    arg0.append(v0, v1);
}, arguments) };

export function __wbg_appendChild_bd383ec5356c0bdb() { return handleError(function (arg0, arg1) {
    const ret = arg0.appendChild(arg1);
    return ret;
}, arguments) };

export function __wbg_new_7d30e9d9d2deaf9d() { return handleError(function (arg0, arg1) {
    var v0 = getCachedStringFromWasm0(arg0, arg1);
    const ret = new URL(v0);
    return ret;
}, arguments) };

export function __wbg_history_370f36be0803466b() { return handleError(function (arg0) {
    const ret = arg0.history;
    return ret;
}, arguments) };

export function __wbg_innerWidth_0bae627f0302b204() { return handleError(function (arg0) {
    const ret = arg0.innerWidth;
    return ret;
}, arguments) };

export function __wbg_innerHeight_dc4c81e04e8bc294() { return handleError(function (arg0) {
    const ret = arg0.innerHeight;
    return ret;
}, arguments) };

export function __wbindgen_closure_wrapper349(arg0, arg1, arg2) {
    const ret = makeMutClosure(arg0, arg1, 35, __wbg_adapter_38);
    return ret;
};

export function __wbindgen_closure_wrapper411(arg0, arg1, arg2) {
    const ret = makeMutClosure(arg0, arg1, 35, __wbg_adapter_41);
    return ret;
};

export function __wbindgen_closure_wrapper711(arg0, arg1, arg2) {
    const ret = makeClosure(arg0, arg1, 35, __wbg_adapter_44);
    return ret;
};

export function __wbindgen_closure_wrapper1221(arg0, arg1, arg2) {
    const ret = makeMutClosure(arg0, arg1, 35, __wbg_adapter_38);
    return ret;
};

export function __wbindgen_closure_wrapper3464(arg0, arg1, arg2) {
    const ret = makeClosure(arg0, arg1, 35, __wbg_adapter_49);
    return ret;
};

export function __wbindgen_closure_wrapper3469(arg0, arg1, arg2) {
    const ret = makeClosure(arg0, arg1, 35, __wbg_adapter_52);
    return ret;
};

export function __wbindgen_closure_wrapper3476(arg0, arg1, arg2) {
    const ret = makeClosure(arg0, arg1, 35, __wbg_adapter_55);
    return ret;
};

export function __wbindgen_closure_wrapper3481(arg0, arg1, arg2) {
    const ret = makeClosure(arg0, arg1, 35, __wbg_adapter_58);
    return ret;
};

export function __wbindgen_closure_wrapper3488(arg0, arg1, arg2) {
    const ret = makeClosure(arg0, arg1, 35, __wbg_adapter_61);
    return ret;
};

export function __wbindgen_closure_wrapper3494(arg0, arg1, arg2) {
    const ret = makeClosure(arg0, arg1, 35, __wbg_adapter_64);
    return ret;
};

export function __wbindgen_closure_wrapper3500(arg0, arg1, arg2) {
    const ret = makeClosure(arg0, arg1, 35, __wbg_adapter_67);
    return ret;
};

export function __wbindgen_closure_wrapper3510(arg0, arg1, arg2) {
    const ret = makeClosure(arg0, arg1, 35, __wbg_adapter_70);
    return ret;
};

export function __wbindgen_closure_wrapper3515(arg0, arg1, arg2) {
    const ret = makeClosure(arg0, arg1, 35, __wbg_adapter_73);
    return ret;
};

export function __wbindgen_closure_wrapper3522(arg0, arg1, arg2) {
    const ret = makeClosure(arg0, arg1, 35, __wbg_adapter_76);
    return ret;
};

export function __wbindgen_closure_wrapper3529(arg0, arg1, arg2) {
    const ret = makeClosure(arg0, arg1, 35, __wbg_adapter_79);
    return ret;
};

export function __wbindgen_closure_wrapper3533(arg0, arg1, arg2) {
    const ret = makeClosure(arg0, arg1, 35, __wbg_adapter_82);
    return ret;
};

export function __wbindgen_closure_wrapper3543(arg0, arg1, arg2) {
    const ret = makeClosure(arg0, arg1, 35, __wbg_adapter_85);
    return ret;
};

export function __wbindgen_closure_wrapper3550(arg0, arg1, arg2) {
    const ret = makeMutClosure(arg0, arg1, 35, __wbg_adapter_88);
    return ret;
};

export function __wbindgen_closure_wrapper3559(arg0, arg1, arg2) {
    const ret = makeClosure(arg0, arg1, 35, __wbg_adapter_91);
    return ret;
};

export function __wbindgen_closure_wrapper3564(arg0, arg1, arg2) {
    const ret = makeClosure(arg0, arg1, 35, __wbg_adapter_94);
    return ret;
};

export function __wbindgen_closure_wrapper3569(arg0, arg1, arg2) {
    const ret = makeClosure(arg0, arg1, 35, __wbg_adapter_97);
    return ret;
};

export function __wbindgen_closure_wrapper3581(arg0, arg1, arg2) {
    const ret = makeClosure(arg0, arg1, 35, __wbg_adapter_100);
    return ret;
};

export function __wbindgen_closure_wrapper3591(arg0, arg1, arg2) {
    const ret = makeClosure(arg0, arg1, 35, __wbg_adapter_103);
    return ret;
};

export function __wbindgen_closure_wrapper3874(arg0, arg1, arg2) {
    const ret = makeMutClosure(arg0, arg1, 35, __wbg_adapter_106);
    return ret;
};

export function __wbindgen_init_externref_table() {
    const table = wasm.__wbindgen_export_2;
    const offset = table.grow(4);
    table.set(0, undefined);
    table.set(offset + 0, undefined);
    table.set(offset + 1, null);
    table.set(offset + 2, true);
    table.set(offset + 3, false);
    ;
};

